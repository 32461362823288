jQuery(document).ready(function ($) {

  $(document).scroll(function (e) {
    if (e.originalEvent.pageYOffset > 0) {
      if (!$('.site-header').hasClass('stuck')) {
        $('.site-header').addClass('stuck');
      }

      if (!$('body').hasClass('scroll')) {
        $('body').addClass('scroll');
      }
    } else {
      $('body').removeClass('scroll');
      $('.site-header').removeClass('stuck');
    }
  });

  $(".scroll-top").click(function (e) {
    e.preventDefault();
    $("html, body").animate({ scrollTop: 0 }, "fast");
    return false;
  });
});